
function Error(){
    return(
        <>
        <link rel='stylesheet'type='text/css' href='../../../error.css'></link> 
        <main>
            
            <h1>4<span><i className="fas fa-ghost"></i></span>4</h1>
            <h2>
                <span style={{color:"#52ce9c"}}>Error: 404 </span>page not found</h2>
            <p>Sorry, the page you're looking for cannot be accessed</p>
        </main>
        </>
    );
}
export default Error;